<template>
  <div class="logged-in-wallet">
    <span class="wallet-prefix-label"> {{ $t('profilePageLoggedInVia') }}</span>
    <span class="main-container">
      <span v-if="walletInfo?.name" :class="`wallet-icon ${walletIconKeyByName(walletInfo.name)}-add-to-wallet-icon`" />
      <span class="wallet-name">{{ walletInfo?.name }}</span>
    </span>
  </div>
</template>

<script setup lang="ts">
import { useWalletInfo } from '@web3modal/ethers/vue';

const { walletInfo } = useWalletInfo();
</script>

<style scoped lang="scss">
.logged-in-wallet {
  display: flex;
  align-items: center;
  gap: 10px;

  .wallet-prefix-label {
    font-family: 'Grenze', sans-serif;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
  }

  .main-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .wallet-name {
    font-family: Grenze;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    color: #fff;
  }

  .wallet-icon {
    display: block;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .metamask-add-to-wallet-icon {
    background-image: url('~/public/img/icons/metamask.svg');
  }

  .okx-add-to-wallet-icon {
    background-image: url('~/public/img/icons/okxwallet.svg');
  }

  .coinbase-add-to-wallet-icon {
    background-image: url('~/public/img/icons/coinbaseWallet.svg');
  }

  .trust-add-to-wallet-icon {
    background-image: url('~/public/img/icons/trustWallet.svg');
  }
}

@media screen and (max-width: 767px) {
  .logged-in-wallet {
    justify-content: center;
    flex-direction: column;
    gap: 12px;

    .wallet-prefix-label {
      font-size: 22px;
    }

    .wallet-name {
      font-size: 18px;
    }

    .wallet-icon {
      height: 20px;
      width: 20px;
    }
  }
}
</style>
