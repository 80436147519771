<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11183_71585)">
      <path
        d="M6.5 6.63813V17.0625C6.49984 18.0939 6.892 19.0868 7.59694 19.8397C8.30188 20.5926 9.26681 21.0492 10.296 21.1169L10.5625 21.125H17.7352C17.5672 21.6002 17.2561 22.0116 16.8446 22.3026C16.4331 22.5936 15.9415 22.7499 15.4375 22.75H9.75C8.45707 22.75 7.21709 22.2364 6.30286 21.3221C5.38862 20.4079 4.875 19.1679 4.875 17.875V8.9375C4.87474 8.43322 5.03088 7.94127 5.32192 7.52945C5.61296 7.11763 6.02456 6.80622 6.5 6.63813ZM18.6875 3.25C19.334 3.25 19.954 3.50681 20.4111 3.96393C20.8682 4.42105 21.125 5.04103 21.125 5.6875V17.0625C21.125 17.709 20.8682 18.329 20.4111 18.7861C19.954 19.2432 19.334 19.5 18.6875 19.5H10.5625C9.91604 19.5 9.29605 19.2432 8.83893 18.7861C8.38181 18.329 8.125 17.709 8.125 17.0625V5.6875C8.125 5.04103 8.38181 4.42105 8.83893 3.96393C9.29605 3.50681 9.91604 3.25 10.5625 3.25H18.6875Z"
        fill="white"
      />
      <path
        d="M18.6997 3.25482C19.3462 3.25482 19.9662 3.51163 20.4233 3.96875C20.8804 4.42587 21.1372 5.04586 21.1372 5.69232V17.0673C21.1372 17.7138 20.8804 18.3338 20.4233 18.7909C19.9662 19.248 19.3462 19.5048 18.6997 19.5048H10.5747C9.92824 19.5048 9.30825 19.248 8.85113 18.7909C8.39401 18.3338 8.13721 17.7138 8.13721 17.0673V5.69232C8.13721 5.04586 8.39401 4.42587 8.85113 3.96875C9.30825 3.51163 9.92824 3.25482 10.5747 3.25482H18.6997Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_11183_71585">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
