import { useDebounceFn } from '@vueuse/core';

export default (copyValue: Ref<string | undefined>) => {
  const copied = ref(false);

  const copy = useDebounceFn(async () => {
    if (!navigator.clipboard || !copyValue.value) {
      return;
    }

    await navigator.clipboard.writeText(copyValue.value);
    copied.value = true;

    setTimeout(() => {
      copied.value = false;
    }, 2000);
  });

  return { copied, copy };
};
